import { createFileRoute } from '@tanstack/react-router'

import { PageContent } from '@/components/page-content'
import { PageHeaderSkeleton } from '@/components/skeleton/page-header-skeleton'
import { TableSkeleton } from '@/components/ui/table'
import { recruitersQueryOptions } from '@/modules/recruiter/presentation/hooks/useRecruiters'

export const Route = createFileRoute('/_authenticated/settings/recruiters')({
  beforeLoad: () => {
    return {
      meta: {
        title: 'Settings - Recruiters',
      },
    }
  },
  loader: ({ context: { queryClient } }) => queryClient.ensureQueryData(recruitersQueryOptions),
  pendingComponent: () => (
    <>
      <PageHeaderSkeleton cta title="Recruiters" />
      <PageContent>
        <TableSkeleton />
      </PageContent>
    </>
  ),
})
