import { Link } from '@tanstack/react-router'

import { cn } from '@/lib/utils'
import { NotificationDrawer } from '@/modules/notification/presentation/components/NotificationDrawer'

import { Navbar } from './navbar'
import { UserButton } from './user-button'

export function Header() {
  const isDev = import.meta.env.MODE === 'development'
  const isStaging = import.meta.env.MODE === 'staging'

  return (
    <header
      className={cn(
        'fixed top-0 z-30 flex h-16 w-full items-center gap-x-12 bg-primary px-8',
        isDev && 'bg-primary-dev',
        isStaging && 'bg-primary-staging',
      )}
    >
      <Link
        className={cn(
          'flex flex-col items-start text-3xl font-semibold uppercase text-white',
          (isDev || isStaging) && 'text-xl',
        )}
        title="Go to home"
        to="/"
      >
        ATS
        {isDev && <span className="rounded-sm bg-white px-1 text-xs font-light text-primary-dev">dev</span>}
        {isStaging && <span className="rounded-sm bg-white px-1 text-xs font-light text-primary-staging">staging</span>}
      </Link>
      <Navbar />
      <div className="ml-auto flex items-center gap-x-2">
        <NotificationDrawer />
        <UserButton />
      </div>
    </header>
  )
}
