import { Link } from '@tanstack/react-router'

import { useJobFilters } from '@/hooks/use-job-filters'

const NAVBAR_LINKS = [
  {
    href: '/jobs',
    label: 'Jobs',
  },
  {
    href: '/applicants',
    label: 'Applicants',
  },
  {
    href: '/objectives',
    label: 'Objectives',
  },
  {
    href: '/settings',
    label: 'Settings',
  },
]

export function Navbar() {
  const { jobFilters } = useJobFilters()

  return (
    <nav className="flex gap-x-8 overflow-hidden">
      {NAVBAR_LINKS.map(({ href, label }) => {
        return (
          <Link
            activeProps={{
              className: 'opacity-100',
            }}
            className="relative flex items-center text-sm tracking-wider text-primary-foreground transition-opacity hover:opacity-100"
            inactiveProps={{
              className: 'opacity-80',
            }}
            key={href}
            search={href === '/jobs' ? jobFilters : undefined}
            to={href}
          >
            {label}
          </Link>
        )
      })}
    </nav>
  )
}
