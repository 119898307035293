// useJobBoard.ts
import { useState, useEffect, useMemo } from 'react'

import { DragStartEvent, DragEndEvent, DragOverEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useParams } from '@tanstack/react-router'
import { toast } from 'sonner'

import { useAlertModal } from '@/components/shared/AlertSuccessModal/AlertSuccessModalProvider'
import { useRefusalReasonModal } from '@/components/shared/RefusalReasonModal/RefusalReasonModalProvider'
import { Progress } from '@/components/ui/progress'
import { Text } from '@/components/ui/text'
import { useUpdateApplicationsStage } from '@/hooks/use-applications-queries'
import { JobBoardApplicationWithStageId } from '@/types/application/job-board'
import { JobBoardColumn, JobBoardStage } from '@/types/stage/job-board'
import { getApplicationMatchingColor } from '@/utils/color'

import { isDroppable } from './utils'

interface JobBoardState {
  applications: JobBoardApplicationWithStageId[]
  activeApplication: JobBoardApplicationWithStageId | null
  previousApplications: JobBoardApplicationWithStageId[]
  activeColor: string
  columns: JobBoardColumn[]
  columnsIds: string[]
}

export const formatStages = (stages: JobBoardStage[]): JobBoardColumn[] => {
  return stages.map((stage) => ({
    color: stage.color,
    count: stage.applications.length,
    description: stage.description,
    id: stage.id,
    isDroppable: isDroppable(stage.order),
    name: stage.name,
    order: stage.order,
  }))
}

export const formatApplications = (stages: JobBoardStage[]): JobBoardApplicationWithStageId[] => {
  return stages.flatMap((stage) => {
    return stage.applications.map((application) => {
      return {
        ...application,
        fromStageId: stage.id,
        isDroppable: isDroppable(stage.order),
        stageId: stage.id,
        stageName: stage.name,
      }
    })
  })
}

export function useJobBoard(stages: JobBoardStage[]) {
  const { jobId } = useParams({ from: '/_authenticated/jobs_/$jobId' })
  const { mutate: updateApplicationsStage } = useUpdateApplicationsStage(jobId)
  const { openModal } = useRefusalReasonModal()
  const { openModal: openAlertModal } = useAlertModal()

  const [state, setState] = useState<JobBoardState>(() => {
    const formattedApplications = formatApplications(stages)
    const formattedColumns = formatStages(stages)
    return {
      activeApplication: null,
      activeColor: '',
      applications: formattedApplications,
      columns: formattedColumns,
      columnsIds: formattedColumns.map((column) => column.id),
      previousApplications: formattedApplications,
    }
  })

  const refusedStage = useMemo(() => stages.find(({ order }) => order === 110), [stages])
  const successStage = useMemo(() => stages.find(({ order }) => order === 100), [stages])

  useEffect(() => {
    const formattedApplications = formatApplications(stages)
    const formattedColumns = formatStages(stages)
    setState((prev) => ({
      ...prev,
      applications: formattedApplications,
      columns: formattedColumns,
      columnsIds: formattedColumns.map((column) => column.id),
    }))
  }, [stages])

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  )

  const handleDragStart = (event: DragStartEvent) => {
    if (event.active.data.current?.type === 'card') {
      const active = event.active.data.current.application as JobBoardApplicationWithStageId

      if (active) {
        const currentColumn = state.columns.find((column) => column.id === active.stageId)
        setState((prev) => ({
          ...prev,
          activeApplication: active,
          activeColor: currentColumn?.color || '',
          previousApplications: [...prev.applications],
        }))
      }
    }
  }

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event

    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    const isActiveACard = active.data.current?.type === 'card'
    const isOverACard = over.data.current?.type === 'card'

    if (!isActiveACard) return

    // Dropping a card over another card
    if (isActiveACard && isOverACard) {
      const overCard = over.data.current?.application as JobBoardApplicationWithStageId
      if (overCard.isDroppable) {
        setState((prev) => {
          const activeIndex = prev.applications.findIndex((application) => application.id === activeId)
          const overIndex = prev.applications.findIndex((application) => application.id === overId)

          const newApplications = [...prev.applications]
          newApplications[activeIndex] = {
            ...newApplications[activeIndex],
            stageId: newApplications[overIndex].stageId,
          }

          return {
            ...prev,
            applications: arrayMove(newApplications, activeIndex, overIndex),
          }
        })
      }
    }

    // Dropping a card over a column
    const isOverAColumn = over.data.current?.type === 'column'
    if (isActiveACard && isOverAColumn) {
      const overColumn = over.data.current?.column as JobBoardColumn
      const activeCard = active.data.current?.application as JobBoardApplicationWithStageId

      if (overColumn.isDroppable || !activeCard.isDroppable) {
        setState((prev) => {
          const activeIndex = prev.applications.findIndex((application) => application.id === activeId)

          const newApplications = [...prev.applications]
          newApplications[activeIndex] = {
            ...newApplications[activeIndex],
            stageId: overId as string,
          }

          return {
            ...prev,
            applications: arrayMove(newApplications, activeIndex, activeIndex),
          }
        })
      }
    }
  }

  const handleRevert = () => {
    setState((prev) => ({
      ...prev,
      activeApplication: null,
      activeColor: '',
      applications: prev.previousApplications,
    }))
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { over } = event
    if (!over) return

    let overApplication = null
    if (over.data.current?.type === 'column') {
      const overColumn = over.data.current?.column as JobBoardColumn
      if (!overColumn.isDroppable) {
        handleRevert()
        return false
      }
      overApplication = state.applications.find((application) => application.stageId === over.id)
    } else {
      const overCard = over.data.current?.application as JobBoardApplicationWithStageId
      if (!overCard.isDroppable && overCard.id !== state.activeApplication?.id) {
        handleRevert()
        return false
      }
      overApplication = state.applications.find((application) => application.id === over.id)
    }

    if (!state.activeApplication || !overApplication) return
    if (state.activeApplication.fromStageId === overApplication.stageId) return

    if (refusedStage?.id === overApplication.stageId) {
      openModal({
        applicationId: state.activeApplication.id,
        fromStageId: state.activeApplication.fromStageId,
        onCancel: handleRevert,
        toStageId: overApplication.stageId,
      })
      handleRevert()
      return
    }

    if (successStage?.id === overApplication.stageId) {
      openAlertModal({
        cancelLabel: 'Go back',
        confirmLabel: 'Confirm & move application',
        description:
          'Moving this application to the <strong>Success Stage</strong> will trigger automated actions that cannot be undone. Once confirmed, you will not be able to move the application back to a previous stage. Are you sure you want to proceed?',
        onCancel: () => handleRevert(),
        onConfirm: () => {
          handleUpdateApplicationsStage({
            activeApplication: state.activeApplication!,
            overApplication,
          })
        },
        title: 'Finalize Application Process?',
      })
      return
    }

    handleUpdateApplicationsStage({
      activeApplication: state.activeApplication,
      overApplication,
    })
  }

  const handleUpdateApplicationsStage = ({
    activeApplication,
    overApplication,
  }: {
    activeApplication: JobBoardApplicationWithStageId
    overApplication: JobBoardApplicationWithStageId
  }) => {
    updateApplicationsStage(
      {
        applicationId: activeApplication.id,
        body: {
          fromStageId: activeApplication.fromStageId,
          toStageId: overApplication.stageId,
        },
      },
      {
        onError: () => {
          handleRevert()
          toast.error(`Error updating stage's application`)
        },
        onSettled: () => {
          setState((prev) => ({ ...prev, activeApplication: null }))
        },
        onSuccess: ({ stageTarget, stageValue }) => {
          const title = 'Applicant moved successfully.'

          if (stageTarget && stageValue) {
            const percent = Math.round((stageValue / stageTarget) * 100)

            toast(title, {
              description: (
                <div className="w-full space-y-1">
                  <Progress value={percent} variant={getApplicationMatchingColor(percent)} />
                  <Text size="xs">
                    You reach {stageValue}/{stageTarget} of your objective.
                  </Text>
                </div>
              ),
            })
          } else {
            toast.success(title)
          }
        },
      },
    )
  }

  return {
    handleDragEnd,
    handleDragOver,
    handleDragStart,
    sensors,
    state,
  }
}
