import { zodResolver } from '@hookform/resolvers/zod'
import { UseMutationResult } from '@tanstack/react-query'
import { DefaultValues, useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { AvailabilityStatus } from '@/shared/domain/enums/availability/availability-status.enum'
import {
  UpdateJobPreferencesData,
  updateJobPreferencesSchema,
} from '@/shared/domain/schemas/update-job-preferences.schema'
import { JobPreferences } from '@/types/application/applicant-info'

import { convertJobPreferencesToDto, formatJobPreferences } from '../presenters/formatJobPreferences'

export const DEFAULT_SALARY_EXPECTATION: DefaultValues<UpdateJobPreferencesData['salaryExpectations']> = {
  apprenticeship: {
    enabled: false,
  },
  fixedTerm: {
    enabled: false,
  },
  freelance: {
    enabled: false,
  },
  internship: {
    enabled: false,
  },
  permanent: {
    enabled: false,
  },
}

export const DEFAULT_FORM_VALUES: DefaultValues<UpdateJobPreferencesData> = {
  availability: null,
  availabilityDelay: undefined,
  availabilityPeriod: null,
  availabilityStatus: AvailabilityStatus.KNOW_DATE,
  salaryExpectations: DEFAULT_SALARY_EXPECTATION,
}

export const useApplicantJobPreferences = ({
  defaultValues,
  mutation,
}: {
  defaultValues: JobPreferences
  mutation: UseMutationResult<JobPreferences, Error, UpdateJobPreferencesData, unknown>
}) => {
  const form = useForm<UpdateJobPreferencesData>({
    defaultValues: defaultValues ? formatJobPreferences(defaultValues) : DEFAULT_FORM_VALUES,
    resolver: zodResolver(updateJobPreferencesSchema),
  })

  const { isPending, mutate: updateJobPreferences } = mutation

  const onSubmit = form.handleSubmit((data) => {
    updateJobPreferences(convertJobPreferencesToDto(data), {
      onSuccess: (result) => {
        toast.success('Job preferences updated successfully.')
        form.reset(formatJobPreferences(result))
      },
    })
  })

  const salaryExpectationsFields = Object.keys(DEFAULT_SALARY_EXPECTATION) as Array<
    keyof UpdateJobPreferencesData['salaryExpectations']
  >

  return { form, isPending, onSubmit, salaryExpectationsFields }
}
