import { ReactNode } from 'react'

import { FolderOpenIcon } from 'lucide-react'

import { EmptyState } from '@/components/empty-state'
import { FilePreview } from '@/components/file-preview'
import { ApplicantInfo } from '@/types/application/applicant-info'
import { getFileNameParts } from '@/utils/file'

export const ApplicantResume = ({
  emptyStateAction,
  emptyStateSubtitle,
  emptyStateTitle = 'No resume',
  resume,
}: {
  emptyStateAction?: ReactNode
  emptyStateSubtitle?: string
  emptyStateTitle?: string
  resume?: ApplicantInfo['resume']
}) => {
  if (!resume) {
    return (
      <EmptyState icon={FolderOpenIcon} subtitle={emptyStateSubtitle} title={emptyStateTitle || 'No resume'}>
        {emptyStateAction}
      </EmptyState>
    )
  }

  const { name } = getFileNameParts(resume.url)

  return (
    <div className="relative h-[calc(100vh-theme(spacing.56))] w-full outline outline-muted">
      <FilePreview mimeType={resume.mimeType} name={name} url={resume.url} />
    </div>
  )
}
