import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Speech } from 'lucide-react'
import { z } from 'zod'

import { EmptyState } from '@/components/empty-state'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { ApplicantInformations } from '@/features/jobs/applications/$applicationId/applicant-informations'
import { ApplicationStage } from '@/features/jobs/applications/$applicationId/application-stage'
import { FilesDocuments } from '@/features/jobs/applications/$applicationId/files/files-documents'
import { FilesDropzone } from '@/features/jobs/applications/$applicationId/files/files-dropzone'
import { ApplicationAccordion } from '@/features/jobs/applications/$applicationId/index/application-accordion'
import { InterviewsDrawer } from '@/features/jobs/applications/$applicationId/interviews/interviews-drawer'
import { Note } from '@/features/jobs/applications/$applicationId/interviews/note'
import { Activities } from '@/features/jobs/applications/$applicationId/shared/activities'
import { applicationsApplicantInfoQuery } from '@/hooks/use-applications-queries'
import { applicationsInterviewsQuery } from '@/queries/use-applications-queries'
import { getFullName } from '@/utils/string'

export const Route = createFileRoute('/_authenticated/jobs_/$jobId/applications/$applicationId')({
  component: ApplicantsApplicationId,
  validateSearch: z.object({
    view: z.enum(['application', 'interviews', 'files']).default('application'),
  }),
})

function ApplicantsApplicationId() {
  const { applicationId, jobId } = Route.useParams()
  const navigate = Route.useNavigate()
  const { view } = Route.useSearch()

  const { data: interviews } = useQuery(applicationsInterviewsQuery(applicationId))
  const { data: applicantInfo } = useQuery(applicationsApplicantInfoQuery(applicationId))

  if (!applicantInfo) return null

  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) {
          navigate({
            params: { jobId },
            to: '/jobs/$jobId',
          }).catch(() => {})
        }
      }}
      open
    >
      <DialogContent className="h-[calc(100%-theme(spacing.8))] min-w-[calc(100%-theme(spacing.8))] overflow-hidden p-0">
        <DialogHeader className="hidden">
          <DialogTitle className="hidden">{getFullName(applicantInfo.applicant)}</DialogTitle>
          <DialogDescription className="hidden">{getFullName(applicantInfo.applicant)}</DialogDescription>
        </DialogHeader>
        <div className="grid h-full grid-cols-[_1fr,theme(spacing.96)] grid-rows-[_auto,_1fr] overflow-hidden bg-background">
          <ApplicantInformations applicationId={applicationId} />
          <ApplicationStage
            applicantId={applicantInfo?.applicant.id}
            applicationId={applicationId}
            jobTitle={applicantInfo?.job.title}
          />
          <Tabs
            className="flex flex-col overflow-hidden"
            onValueChange={(value) =>
              navigate({
                search: {
                  view: value as 'application' | 'interviews' | 'files',
                },
              })
            }
            value={view}
          >
            <TabsList>
              <TabsTrigger value="application">Application</TabsTrigger>
              <TabsTrigger value="interviews">Interviews</TabsTrigger>
              <TabsTrigger value="files">Files</TabsTrigger>
            </TabsList>
            <div className="flex-1 overflow-auto p-8">
              <TabsContent value="application">
                <ApplicationAccordion applicationId={applicationId} />
              </TabsContent>
              <TabsContent value="interviews">
                <div className="relative pb-16">
                  {!!interviews?.length && <Separator className="absolute left-1/2" orientation="vertical" />}
                  <div className="flex justify-end">
                    <InterviewsDrawer />
                  </div>
                  {interviews?.length ? (
                    <div className="container relative mt-8 flex max-w-xl flex-col gap-y-8">
                      {interviews?.map((interview) => <Note interview={interview} key={interview.id} />)}
                    </div>
                  ) : (
                    <EmptyState className="mt-8" icon={Speech} title="No interviews." />
                  )}
                </div>
              </TabsContent>
              <TabsContent value="files">
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 2xl:grid-cols-3">
                  <FilesDropzone />
                  <FilesDocuments />
                </div>
              </TabsContent>
            </div>
          </Tabs>
          <Activities />
        </div>
      </DialogContent>
    </Dialog>
  )
}
