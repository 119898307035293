import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'

import { applicationsDocumentsQuery } from '@/queries/use-applications-queries'

import { Document } from './document'

export function FilesDocuments() {
  const { applicationId } = useParams({ from: '/_authenticated/jobs_/$jobId/applications/$applicationId' })
  const { data: documents } = useQuery(applicationsDocumentsQuery(applicationId))

  return documents?.map((document) => <Document key={document.id} {...document} />)
}
