import { zodResolver } from '@hookform/resolvers/zod'
import { useParams } from '@tanstack/react-router'
import { Send } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { CustomFormField, FormFieldType } from '@/components/custom-form-field'
import { Tiptap } from '@/components/tiptap/tiptap'
import { Button } from '@/components/ui/button'
import { Form } from '@/components/ui/form'
import { useCreateApplicationsComment } from '@/hooks/use-applications-queries'
import { useAuth } from '@/hooks/use-auth'
import { TargetNotificationType } from '@/modules/notification/domain/enums/target-notification.enum'
import { useCreateNotification } from '@/modules/notification/presentation/hooks/useNotifications'
import { useRecruitersMinimal } from '@/modules/recruiter/presentation/hooks/useRecruiters'
import { PostCommentValues, postCommentSchema } from '@/schemas/applications/comments/create'
import { extractUniqueMentionIds, getFullName, removeHTMLTags } from '@/utils/string'

export function ActivityForm() {
  const { applicationId } = useParams({ from: '/_authenticated/jobs_/$jobId/applications/$applicationId' })
  const { user } = useAuth()
  const form = useForm<PostCommentValues>({
    defaultValues: {
      comment: '',
    },
    resolver: zodResolver(postCommentSchema),
  })

  const { isPending, mutate: createComment } = useCreateApplicationsComment(applicationId)
  const { data: recruiters } = useRecruitersMinimal({
    select: (data) =>
      data
        .map((recruiter) => ({ id: recruiter.id, label: getFullName(recruiter) }))
        .filter(({ id }) => id !== user?.id),
  })
  const { mutate: createNotification } = useCreateNotification()

  const { control, handleSubmit, reset } = form

  const onSubmit = (values: PostCommentValues) => {
    const ids = extractUniqueMentionIds(values.comment)
    const comment = removeHTMLTags(values.comment)

    createComment(
      {
        body: {
          comment,
        },
      },
      {
        onSuccess: () => {
          if (ids.length) {
            createNotification(
              ids.map((id) => ({
                content: comment,
                receiverId: id,
                targetId: applicationId,
                targetNotificationType: TargetNotificationType.APPLICATION,
              })),
            )
          }
          reset()
          toast.success('Comment added successfully.')
        },
      },
    )
  }

  return (
    <Form {...form}>
      <form className="mt-2 flex flex-col gap-y-2" onSubmit={handleSubmit(onSubmit)}>
        {recruiters && (
          <CustomFormField
            control={control}
            fieldType={FormFieldType.SKELETON}
            name="comment"
            placeholder="Add a comment..."
            renderSkeleton={(field) => (
              <Tiptap
                className="min-h-24"
                extensions="withoutToolbar"
                name={field.name}
                onChange={field.onChange}
                placeholder="Add a comment..."
                suggestionItems={recruiters || []}
                value={field.value}
              />
            )}
          />
        )}
        <Button className="ml-auto" isLoading={isPending} size="icon" type="submit">
          <Send size={16} />
        </Button>
      </form>
    </Form>
  )
}
