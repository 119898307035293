import {
  RecruiterMinimalResponseDto,
  RecruiterResponseDto,
  UpdateRecruiterResponseDto,
} from '@/modules/recruiter/domain/entities/recruiter.entity'
import { IRecruiterRepository } from '@/modules/recruiter/domain/repositories/recruiter.repository'
import { CreateOrUpdateRecruiterData } from '@/modules/recruiter/domain/schemas/create-or-update.schema'
import { IdResponseDto } from '@/shared/domain/entities/common.entity'

export class RecruiterUseCase {
  constructor(private recruiterRepository: IRecruiterRepository) {}

  async getAll(): Promise<RecruiterResponseDto[]> {
    return this.recruiterRepository.getAll()
  }

  async getAllMinimal(): Promise<RecruiterMinimalResponseDto[]> {
    return this.recruiterRepository.getAllMinimal()
  }

  async update(recruiterId: string, data: CreateOrUpdateRecruiterData): Promise<UpdateRecruiterResponseDto> {
    return this.recruiterRepository.update(recruiterId, data)
  }

  async activate(recruiterId: string, organizationId: string): Promise<IdResponseDto> {
    return this.recruiterRepository.activate(recruiterId, organizationId)
  }

  async create(data: CreateOrUpdateRecruiterData): Promise<void> {
    return this.recruiterRepository.create(data)
  }
}
