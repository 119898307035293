import { useQueryClient, QueryKey } from '@tanstack/react-query'

type DataWithId = { id: string }

export function useUpdateCache() {
  const queryClient = useQueryClient()

  return {
    addToList: <T>(queryKey: QueryKey, newData: T) => {
      queryClient.setQueryData<T[] | undefined>(queryKey, (oldData) => {
        if (!oldData) return [newData]
        return [...oldData, newData]
      })
    },
    invalidateQueries: async (queryKey: QueryKey) => {
      await queryClient.invalidateQueries({ queryKey })
    },

    updateList: <T extends DataWithId>(queryKey: QueryKey, newData: T) => {
      const { id, ...rest } = newData
      queryClient.setQueryData<T[] | undefined>(queryKey, (oldData) => {
        if (!oldData) return oldData
        return oldData.map((item) => (item.id === id ? { ...item, ...rest } : item))
      })
    },

    updatePaginatedList: <T extends DataWithId>(queryKey: QueryKey, newData: T) => {
      queryClient.setQueryData<{ items: T[]; pagination: unknown }>(queryKey, (oldData) => {
        if (!oldData) return oldData
        return {
          ...oldData,
          items: oldData.items.map((item) => (item.id === newData.id ? newData : item)),
        }
      })
    },

    updatePaginatedListPartially: <T extends DataWithId>(
      queryKey: QueryKey,
      id: string,
      partialData: Partial<Omit<T, 'id'>>,
    ) => {
      queryClient.setQueryData<{ items: T[]; pagination: unknown }>(queryKey, (oldData) => {
        if (!oldData) return oldData
        return {
          ...oldData,
          items: oldData.items.map((item) => (item.id === id ? { ...item, ...partialData } : item)),
        }
      })
    },

    updateSingleItem: <T extends DataWithId>(queryKey: QueryKey, newData: T) => {
      queryClient.setQueryData(queryKey, newData)
    },
  }
}
