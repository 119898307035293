import { queryOptions, useMutation, useQuery } from '@tanstack/react-query'

import { ApiGateway } from '@/modules/api/api.gateway'
import { RecruiterRepository } from '@/modules/recruiter/adapter/recruiter.repository'
import { RecruiterUseCase } from '@/modules/recruiter/application/recruiter.use-case'
import { RecruiterMinimalResponseDto, RecruiterResponseDto } from '@/modules/recruiter/domain/entities/recruiter.entity'
import { CreateOrUpdateRecruiterData } from '@/modules/recruiter/domain/schemas/create-or-update.schema'

const apiGateway = new ApiGateway()
const recruiterRepository = new RecruiterRepository(apiGateway)
const recruiterUseCase = new RecruiterUseCase(recruiterRepository)

const recruitersQueryOptions = queryOptions({
  queryFn: () => recruiterUseCase.getAll(),
  queryKey: ['recruiters'],
})

const recruitersMinimalQueryOptions = queryOptions({
  queryFn: () => recruiterUseCase.getAllMinimal(),
  queryKey: ['recruiters', 'minimal'],
})

const useRecruiters = <T,>({ select }: { select?: (data: RecruiterResponseDto[]) => T } = {}) => {
  return useQuery({ ...recruitersQueryOptions, select })
}

const useRecruitersMinimal = <T,>({ select }: { select?: (data: RecruiterMinimalResponseDto[]) => T } = {}) => {
  return useQuery({ ...recruitersMinimalQueryOptions, select })
}

const useUpdateRecruiter = () => {
  return useMutation({
    mutationFn: ({ body, id }: { id: string; body: CreateOrUpdateRecruiterData }) => recruiterUseCase.update(id, body),
  })
}

const useCreateRecruiter = () => {
  return useMutation({
    mutationFn: (data: CreateOrUpdateRecruiterData) => recruiterUseCase.create(data),
  })
}

const useActivateRecruiter = (recruiterId: string) => {
  return useMutation({
    mutationFn: ({ organizationId }: { organizationId: string }) =>
      recruiterUseCase.activate(recruiterId, organizationId),
  })
}

export { recruitersQueryOptions, recruitersMinimalQueryOptions }
export { useRecruiters, useRecruitersMinimal, useUpdateRecruiter, useCreateRecruiter, useActivateRecruiter }
