export interface AlertModalConfig {
  isOpen: boolean
  title: string
  description: string
  onConfirm: () => void
  onCancel?: () => void
  confirmLabel: string
  cancelLabel: string
}

export interface AlertModalContextType {
  openModal: (config: Omit<AlertModalConfig, 'isOpen'>) => void
  closeModal: () => void
}

import { createContext, useContext, useState, useCallback } from 'react'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog'

const AlertModalContext = createContext<AlertModalContextType>({
  closeModal: () => {},
  openModal: () => {},
})

interface AlertModalProviderProps {
  children: React.ReactNode
}

export const AlertModalProvider: React.FC<AlertModalProviderProps> = ({ children }) => {
  const [modalConfig, setModalConfig] = useState<AlertModalConfig>({
    cancelLabel: 'Cancel',
    confirmLabel: 'Confirm',
    description: '',
    isOpen: false,
    onCancel: undefined,
    onConfirm: () => {},
    title: '',
  })

  const openModal = useCallback(
    ({
      cancelLabel = 'Cancel',
      confirmLabel = 'Confirm',
      description,
      onCancel,
      onConfirm,
      title,
    }: Omit<AlertModalConfig, 'isOpen'>) => {
      setModalConfig({
        cancelLabel,
        confirmLabel,
        description,
        isOpen: true,
        onCancel,
        onConfirm,
        title,
      })
    },
    [],
  )

  const closeModal = useCallback(() => {
    setModalConfig((prev) => ({ ...prev, isOpen: false }))
  }, [])

  const handleConfirm = useCallback(() => {
    modalConfig.onConfirm()
    closeModal()
  }, [modalConfig, closeModal])

  const handleCancel = useCallback(() => {
    if (modalConfig.onCancel) {
      modalConfig.onCancel()
    }
    closeModal()
  }, [modalConfig, closeModal])

  return (
    <AlertModalContext.Provider value={{ closeModal, openModal }}>
      {children}
      <AlertDialog onOpenChange={closeModal} open={modalConfig.isOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{modalConfig.title}</AlertDialogTitle>
            <AlertDialogDescription dangerouslySetInnerHTML={{ __html: modalConfig.description }} />
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleCancel}>{modalConfig.cancelLabel}</AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirm}>{modalConfig.confirmLabel}</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </AlertModalContext.Provider>
  )
}

// Custom hook to use the modal
export const useAlertModal = (): AlertModalContextType => {
  const context = useContext(AlertModalContext)
  if (!context) {
    throw new Error('useAlertModal must be used within an AlertModalProvider')
  }
  return context
}
