import * as React from 'react'

import { addYears } from 'date-fns'
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { DayPicker } from 'react-day-picker'

import { cn } from '@/lib/utils'

import { buttonVariants } from './button.style'

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      captionLayout="dropdown"
      className={cn('p-3', className)}
      classNames={{
        button_next: cn(buttonVariants({ variant: 'outline' }), 'h-7 w-7 bg-transparent p-0'),
        button_previous: cn(buttonVariants({ variant: 'outline' }), 'h-7 w-7 bg-transparent p-0'),
        caption_label: 'hidden',
        day: cn(buttonVariants({ variant: 'ghost' }), 'h-8 w-8 p-0 font-normal aria-selected:opacity-100'),
        day_outside: 'day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground',
        disabled: 'text-muted-foreground opacity-50',
        dropdown: 'text-sm outline-none font-semibold',
        dropdowns: 'flex space-x-2',

        hidden: 'invisible',
        month: 'space-y-4 flex flex-col justify-center',
        month_grid: 'border-collapse',
        months: 'flex flex-col relative',

        nav: 'absolute space-x-1 flex items-center right-0',
        outside:
          'day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        range_end: 'day-range-end',
        range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
        range_start: 'day-range-start',
        selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        today: 'bg-accent text-accent-foreground',
        week: 'flex w-full mt-2',
        weekday: 'text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]',
        weekdays: 'flex',
        ...classNames,
      }}
      components={{
        Chevron: ({ orientation }) =>
          orientation === 'left' ? <ChevronLeftIcon size={16} /> : <ChevronRightIcon size={16} />,
      }}
      endMonth={addYears(new Date(), 3)}
      showOutsideDays={showOutsideDays}
      weekStartsOn={1}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

export { Calendar }
