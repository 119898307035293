import { useCallback } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import { CloudUploadIcon, FileIcon, ImageIcon, Trash2Icon } from 'lucide-react'
import { Accept, FileError, FileRejection, useDropzone } from 'react-dropzone'
import { toast } from 'sonner'

import { Size } from '@/constants/size'
import { cn } from '@/lib/utils'
import { displayBytesToMegabytes } from '@/utils/file'

import { Button } from './ui/button'
import { Text } from './ui/text'

interface MultiFileUploadProps {
  accept?: Accept
  maxFiles?: number
  maxSize?: number
  onDrop: (files: File[]) => void
  files: File[]
  removeFile: (file: File) => void
  isPending?: boolean
}

export interface UploadableFile {
  file: File
  errors: FileError[]
}

const FILE_TYPE = {
  'application/msword': FileIcon,
  'application/pdf': FileIcon,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': FileIcon,
  'image/jpeg': ImageIcon,
  'image/jpg': ImageIcon,
  'image/png': ImageIcon,
}

export function MultiFileUpload({
  accept = {
    'application/msword': ['.doc'],
    'application/pdf': ['.pdf'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'image/png': ['.png'],
  },
  files,
  isPending,
  maxFiles = 5,
  maxSize = Size.SIZE_5MB,
  onDrop,
  removeFile,
}: MultiFileUploadProps) {
  const extensions = Object.values(accept).flat()

  const onFileDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (files.length + acceptedFiles.length > maxFiles) {
        toast.error(`Too many files. Max files allowed: ${maxFiles}`)
        return
      }
      if (acceptedFiles.length > 0) {
        onDrop(acceptedFiles)
      }
      if (rejectedFiles.length > 0) {
        switch (rejectedFiles[0].errors[0].code) {
          case 'file-invalid-type':
            toast.error(`Invalid file type. Accepted types: ${extensions.join(', ')}.`)
            break
          case 'file-too-large':
            toast.error(`File too large. Max size allowed: ${displayBytesToMegabytes(maxSize, 0)}.`)
            break
          case 'too-many-files':
            toast.error(`Too many files. Max files allowed: ${maxFiles}.`)
            break
        }
      }
    },
    [extensions, files.length, maxFiles, maxSize, onDrop],
  )

  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    accept,
    disabled: isPending,
    maxFiles,
    maxSize,
    noClick: true,
    noKeyboard: true,
    onDrop: onFileDrop,
  })

  return (
    <section className="flex h-full flex-col gap-y-2">
      <div
        {...getRootProps()}
        className={cn(
          'flex flex-col items-center justify-center gap-y-4 rounded border border-dashed bg-muted p-4 flex-1 text-muted-foreground',
          isDragActive && 'border-primary bg-primary/5',
        )}
      >
        <input {...getInputProps()} />
        <CloudUploadIcon className="size-12" />
        <div className="flex flex-col items-center gap-y-2">
          <Text align="center" size="sm" variant="muted">
            Drag and drop files here ({extensions.join(', ')}) ({displayBytesToMegabytes(maxSize, 0)})
          </Text>
          <Text size="sm" variant="muted" weight="semibold">
            OR
          </Text>
          <Button disabled={isPending} onClick={open} variant="muted-foreground">
            select files
          </Button>
        </div>
      </div>
      {files.length > 0 && (
        <div className="flex flex-col gap-y-1">
          <AnimatePresence>
            {files.map((file) => {
              const Icon = FILE_TYPE[file.type as keyof typeof FILE_TYPE] ?? FileIcon

              return (
                <motion.div
                  animate={{ opacity: 1 }}
                  className="flex items-center gap-x-2 rounded border bg-muted px-4"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                  key={file.name}
                >
                  <Icon className="text-muted-foreground" size={16} />
                  <Text size="sm" truncate variant="muted">
                    {file.name}
                  </Text>
                  <Button
                    className="ml-auto shrink-0 hover:text-destructive"
                    disabled={isPending}
                    onClick={() => removeFile(file)}
                    size="icon"
                    variant="ghost"
                  >
                    <Trash2Icon size={16} />
                  </Button>
                </motion.div>
              )
            })}
          </AnimatePresence>
        </div>
      )}
    </section>
  )
}
