import { useQuery } from '@tanstack/react-query'
import { useParams } from '@tanstack/react-router'
import { MoreHorizontal } from 'lucide-react'
import { toast } from 'sonner'

import { Select } from '@/components/select'
import { useAlertModal } from '@/components/shared/AlertSuccessModal/AlertSuccessModalProvider'
import { useRefusalReasonModal } from '@/components/shared/RefusalReasonModal/RefusalReasonModalProvider'
import { Button } from '@/components/ui/button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@/components/ui/dropdown-menu'
import { Progress } from '@/components/ui/progress'
import { Text } from '@/components/ui/text'
import { Title } from '@/components/ui/title'
import { applicationsJobStagesQuery, useUpdateApplicationsStage } from '@/hooks/use-applications-queries'
import { JobAssignModal } from '@/modules/application/presentation/components/job-assign-modal/JobAssignModal'
import { getApplicationMatchingColor } from '@/utils/color'

import { ContactDialog } from './shared/contact-dialog'

import { isDroppable } from '../../$jobId/utils'

interface ApplicationStageProps {
  applicationId: string
  applicantId: string
  jobTitle?: string
}

export function ApplicationStage({ applicantId, applicationId, jobTitle }: ApplicationStageProps) {
  const { jobId } = useParams({ from: '/_authenticated/jobs_/$jobId/applications/$applicationId' })
  const { data, isLoading } = useQuery(applicationsJobStagesQuery(applicationId))
  const { mutate: updateApplicationsStage } = useUpdateApplicationsStage(jobId)
  const { openModal } = useRefusalReasonModal()
  const { openModal: openAlertModal } = useAlertModal()

  if (!data) return null

  const refusedStage = data?.stages?.find(({ order }) => order === 110)
  const successfulStage = data?.stages?.find(({ order }) => order === 100)

  const filteredOptions = data?.stages.map(({ id, name, order }) => ({
    isDisabled: !isDroppable(order),
    label: name,
    value: id,
  }))

  const handleUpdateStage = (stageId: string) => {
    if (!data?.currentStage) return
    if (data?.currentStage === stageId) return
    if (refusedStage?.id === stageId) {
      openModal({
        applicationId,
        fromStageId: data?.currentStage,
        toStageId: stageId,
      })
      return
    }

    if (successfulStage?.id === stageId) {
      openAlertModal({
        cancelLabel: 'Go back',
        confirmLabel: 'Confirm & move application',
        description:
          'Moving this application to the <strong>Success Stage</strong> will trigger automated actions that cannot be undone. Once confirmed, you will not be able to move the application back to a previous stage. Are you sure you want to proceed?',

        onConfirm: () => {
          handleUpdateApplicationsStage({
            fromStageId: data?.currentStage,
            toStageId: stageId,
          })
        },
        title: 'Finalize Application Process?',
      })
      return
    }

    handleUpdateApplicationsStage({
      fromStageId: data?.currentStage,
      toStageId: stageId,
    })
  }

  const handleUpdateApplicationsStage = ({ fromStageId, toStageId }: { fromStageId: string; toStageId: string }) => {
    updateApplicationsStage(
      { applicationId, body: { fromStageId, toStageId } },
      {
        onSuccess: ({ stageTarget, stageValue }) => {
          const title = `Stage's application updated successfully`
          if (stageTarget && stageValue) {
            const percent = Math.round((stageValue / stageTarget) * 100)

            toast(title, {
              description: (
                <div className="w-full space-y-1">
                  <Progress value={percent} variant={getApplicationMatchingColor(percent)} />
                  <Text size="xs">
                    You reach {stageValue}/{stageTarget} of your objective.
                  </Text>
                </div>
              ),
            })
          } else {
            toast.success(title)
          }
        },
      },
    )
  }

  return (
    <div className="border-b border-l p-8">
      <div className="flex flex-col gap-y-2">
        {jobTitle && <Title level={5}>{jobTitle}</Title>}
        <Select
          isDisabled={refusedStage?.id === data?.currentStage || successfulStage?.id === data?.currentStage}
          isLoading={isLoading}
          menuPosition="absolute"
          onChange={(value) => value && handleUpdateStage(value.value)}
          options={filteredOptions}
          value={filteredOptions?.find(({ value }) => value === data?.currentStage)}
        />
        <div className="flex gap-x-2">
          <ContactDialog />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="icon" variant="outline">
                <MoreHorizontal size={16} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <JobAssignModal applicantId={applicantId} applicationId={applicationId}>
                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>Assign to a job</DropdownMenuItem>
              </JobAssignModal>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </div>
  )
}
